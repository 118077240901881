import React, { Suspense, useEffect, useState } from 'react';
import classnames from "classnames";
import { BounceLoader } from 'react-spinners';
import { Canvas } from '@react-three/fiber';
import { Environment, Html, OrbitControls } from '@react-three/drei';
import { Button, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import { PreviewCard } from '../../../components/Component';
import PortfolioTab from '../../components/common/PortfolioTab';
import Model from '../../components/common/Model';
import FullScreenModel from '../../components/common/FullScreenModel';
import Gallery from '../../components/common/Gallery';

import Silocloud from "../../../images/siloimages/Portfolio/websites/silocloud.png";
import Noit from "../../../images/siloimages/Portfolio/websites/noitavonne.png";
import Nuairs from "../../../images/siloimages/Portfolio/websites/Nuairs.png";
import realFlow from "../../../images/siloimages/Portfolio/websites/therealflow.png";
import representingme from "../../../images/siloimages/Portfolio/websites/representingme.png";
import hbcu from "../../../images/siloimages/Portfolio/websites/hbcu.png";
import model1 from '../../../images/siloimages/Portfolio/3Dthumbnails/FordMustangShelby.png';
import model2 from '../../../images/siloimages/Portfolio/3Dthumbnails/Home01.png';
import model3 from '../../../images/siloimages/Portfolio/3Dthumbnails/Refrigerator.png';
import model4 from '../../../images/siloimages/Portfolio/3Dthumbnails/WashingMachine.png';
import model5 from '../../../images/siloimages/Portfolio/3Dthumbnails/Xiaomiscooter.png';
import model6 from '../../../images/siloimages/Portfolio/3Dthumbnails/Washing Machine01.png';
import graphics1 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb1.jpg';
import graphics2 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb2.jpg';
import graphics3 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb3.jpg';
import graphics4 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb4.jpg';
import graphics5 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb5.jpg';
import graphics6 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb6.jpg';
import graphics7 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb7.jpg';
import graphics8 from '../../../images/siloimages/Portfolio/graphics/thumbnails/thumb8.jpg';
import graphics9 from '../../../images/siloimages/Portfolio/graphics/thumbnails/SiloLogo.jpg';
import graphics10 from '../../../images/siloimages/Portfolio/graphics/thumbnails/NoitLogo.jpg';
import graphics11 from '../../../images/siloimages/Portfolio/graphics/thumbnails/Nuairs.jpg';
import graphics12 from '../../../images/siloimages/Portfolio/graphics/thumbnails/ashtonporter1.jpg';
import graphics13 from '../../../images/siloimages/Portfolio/graphics/thumbnails/ashtonporter2.jpg';
import graphics14 from '../../../images/siloimages/Portfolio/graphics/thumbnails/ashtonporter3.jpg';
import graphics15 from '../../../images/siloimages/Portfolio/graphics/thumbnails/cap.jpg';
import original1 from "../../../images/siloimages/Portfolio/graphics/original/org1.jpg";
import original2 from "../../../images/siloimages/Portfolio/graphics/original/org2.jpg";
import original3 from "../../../images/siloimages/Portfolio/graphics/original/org3.jpg";
import original4 from "../../../images/siloimages/Portfolio/graphics/original/org4.jpg";
import original5 from "../../../images/siloimages/Portfolio/graphics/original/org5.jpg";
import original6 from "../../../images/siloimages/Portfolio/graphics/original/org6.jpg";
import original7 from "../../../images/siloimages/Portfolio/graphics/original/org7.jpg";
import original8 from "../../../images/siloimages/Portfolio/graphics/original/org8.jpg";
import original9 from "../../../images/siloimages/Portfolio/graphics/original/orgSilo.jpg";
import original10 from "../../../images/siloimages/Portfolio/graphics/original/orgNoit.jpg";
import original11 from "../../../images/siloimages/Portfolio/graphics/original/orgNuairs.jpg";
import original12 from "../../../images/siloimages/Portfolio/graphics/original/ashtonporter1.jpg";
import original13 from "../../../images/siloimages/Portfolio/graphics/original/ashtonporter2.jpg";
import original14 from "../../../images/siloimages/Portfolio/graphics/original/ashtonporter3.jpg";
import original15 from "../../../images/siloimages/Portfolio/graphics/original/orgcap.jpg";
import GamesAndTools from '../../components/common/GamesAndTools';

const websitesData = [
    { id: 'Silocloud', title: 'SiloCloud', url: 'https://silocloud.com/', image: Silocloud },
    { id: 'Noit', title: "Noitavonne", url: 'https://noitavonne.com/', image: Noit },
    { id: 'Nuairs', title: 'NuAirs', url: 'https://nuairs.com/', image: Nuairs },
    { id: 'realFlow', title: 'TheRealFlow', url: 'https://therealflow.com/', image: realFlow },
    { id: 'representingme', title: 'RepresentingMe', url: 'https://representingme.com/', image: representingme },
    { id: 'hbcu', title: 'HBCU', url: 'https://www.hbculeaguepass.com/home', image: hbcu },
];

const Portfoliosilo = () => {
    const [activeTab, setActivetab] = useState("1");
    const [model, setModel] = useState({ path: './Models/FordMustangShelbyGt500byhycade.gltf', scale: [0.4, 0.4, 0.4], position: [0, -0.06, 0], rotation: [0.3, 0.8, 0] });
    const [fov, setFov] = useState(50);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggle = (tab) => {
        if (activeTab !== tab) setActivetab(tab);
    };

    useEffect(() => {
        const handleResize = () => {
            setFov(window.innerWidth < 768 ? 30 : 40);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleButtonClick = (path, position, scale, rotation) => {
        setModel({ path, position, scale, rotation });
    };

    const galleryImages = [
        { thumbnail: graphics9, original: original9, tags: ['Logos'] },
        { thumbnail: graphics7, original: original7, tags: ['InfoGraphics'] },
        { thumbnail: graphics2, original: original2, tags: ['Banners'] },
        { thumbnail: graphics11, original: original11, tags: ['Logos'] },
        { thumbnail: graphics3, original: original3, tags: ['Banners'] },
        { thumbnail: graphics4, original: original4, tags: ['InfoGraphics'] },
        { thumbnail: graphics10, original: original10, tags: ['Logos'] },
        { thumbnail: graphics1, original: original1, tags: ['InfoGraphics'] },
        { thumbnail: graphics5, original: original5, tags: ['Banners'] },
        { thumbnail: graphics6, original: original6, tags: ['Banners'] },
        { thumbnail: graphics8, original: original8, tags: ['InfoGraphics'] },
        { thumbnail: graphics15, original: original15, tags: ['others'] },
        { thumbnail: graphics12, original: original12, tags: ['others'] },
        { thumbnail: graphics13, original: original13, tags: ['others'] },
        { thumbnail: graphics14, original: original14, tags: ['others'] },
    ];


    return (
        <div className='nk-content mt-xl-4 mt-md-5 mt-4'>
            <PreviewCard>
                <Nav tabs className="mt-n3">
                    <NavItem>
                        <NavLink tag="a" href="#tab" className={classnames({ active: activeTab === "1" }, 'ff-alt')}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("1");
                            }}> Websites
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag="a" href="#tab" className={classnames({ active: activeTab === "2" }, 'ff-alt')}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("2");
                            }}> 3D Design
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag="a" href="#tab" className={classnames({ active: activeTab === "3" }, 'ff-alt')}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("3");
                            }}> Graphics
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag="a" href="#tab" className={classnames({ active: activeTab === "4" }, 'ff-alt')}
                            onClick={(ev) => {
                                ev.preventDefault();
                                toggle("4");
                            }}> Games & Tools
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="container">
                            <PortfolioTab items={websitesData.slice(0, 3)} titleClassName="text-danger ff-alt" />
                            <PortfolioTab items={websitesData.slice(3, 6)} titleClassName="text-danger ff-alt" />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="container">
                            <Row>
                                <div className="col-12 mb-3 position-relative ">
                                    <Canvas key={model.path} className='rounded-5 border border-2 border-dark' style={{ height: '400px' }} shadows camera={{ position: [0, 0, 2], fov: fov }} dpr={[1, 2]}>
                                        <color attach="background" args={["#efefef"]} />
                                        <ambientLight intensity={0.3} />
                                        <directionalLight
                                            castShadow
                                            position={[5, 5, 5]}
                                            intensity={0.5}
                                            shadow-mapSize={[1024, 1024]}
                                            shadow-camera-far={50}
                                            shadow-camera-left={-10}
                                            shadow-camera-right={10}
                                            shadow-camera-top={10}
                                            shadow-camera-bottom={-10}
                                        />
                                        <spotLight
                                            position={[-5, 10, 2]}
                                            angle={0.3}
                                            penumbra={0.5}
                                            intensity={0.8}
                                            castShadow
                                            shadow-bias={-0.0001}
                                            color="#ffcc99"
                                        />
                                        <spotLight
                                            position={[5, 10, 2]}
                                            angle={0.3}
                                            penumbra={0.5}
                                            intensity={0.6}
                                            castShadow
                                            shadow-bias={-0.0001}
                                            color="#ffcc99"
                                        />
                                        <pointLight position={[0, 5, -5]} intensity={0.3} color="#ffffff" />
                                        <hemisphereLight intensity={0.4} color="#ffffff" groundColor="#444444" />
                                        <OrbitControls enableZoom={true} zoomSpeed={1.5} />
                                        <Environment preset="warehouse" background={false} />
                                        <Suspense fallback={
                                            <Html center>
                                                <div>
                                                    <BounceLoader color='#CE0235' />
                                                </div>
                                            </Html>
                                        }>
                                            <Model modelPath={model.path} position={model.position} scale={model.scale} rotation={model.rotation} />
                                        </Suspense>
                                    </Canvas>
                                    <button
                                        onClick={() => setIsFullScreen(true)}
                                        style={{
                                            position: 'absolute',
                                            bottom: '7px',
                                            right: '20px',
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '50%',
                                            width: '40px',
                                            height: '40px',
                                            fontSize: '20px',
                                            cursor: 'pointer',
                                        }}>
                                        ⛶
                                    </button>
                                </div>
                                <div className="col-12 d-flex justify-content-around">
                                    <div className="d-flex flex-wrap justify-content-center">
                                        {[
                                            { label: 'Model 1', path: './Models/FordMustangShelbyGt500byhycade.gltf', position: [0, -0.06, 0], scale: [0.4, 0.4, 0.4], rotation: [0.3, 0.8, 0], thumbnail: model1 },
                                            { label: 'Model 2', path: './Models/Home01.gltf', position: [0.5, -0.2, 0], scale: [0.06, 0.06, 0.06], rotation: [0, -2, 0], thumbnail: model2 },
                                            { label: 'Model 5', path: './Models/Xiaomielectricscooter.gltf', position: [0, -0.3, 0], scale: [0.1, 0.1, 0.1], rotation: [0, 0.8, 0], thumbnail: model5 },
                                            { label: 'Model 3', path: './Models/Refrigerator.gltf', position: [0, -0.5, 0], scale: [0.18, 0.18, 0.18], rotation: [0, 0.5, 0], thumbnail: model3 },
                                            { label: 'Model 4', path: './Models/WashingMachine.gltf', position: [0.1, -0.5, 0], scale: [0.3, 0.3, 0.3], rotation: [0, 0.5, 0], thumbnail: model4 },
                                            { label: 'Model 5', path: './Models/WashingMachine01.gltf', position: [0, -0.6, 0], scale: [0.4, 0.4, 0.4], rotation: [0, 0.5, 0], thumbnail: model6 },
                                        ].map((item, index) => (
                                            <div key={index} className='p-1 col-md-2 col-6'>
                                                <Button size="sm" className=" mb-2 model-button bg-transparent text-black d-flex justify-content-center align-items-center p-0 rounded rounded-3"
                                                    onClick={() => handleButtonClick(item.path, item.position, item.scale, item.rotation)}>
                                                    <img src={item.thumbnail} alt={item.label} className="model-thumbnail rounded rounded-3"
                                                        style={{
                                                            height: '80px',
                                                            objectFit: 'contain',
                                                            borderRadius: '4px'
                                                        }} />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Row>
                            {isFullScreen && (
                                <FullScreenModel model={model} onClose={() => setIsFullScreen(false)} />
                            )}
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <Gallery images={galleryImages} />
                    </TabPane>
                    <TabPane tabId="4">
                        <GamesAndTools />
                    </TabPane>
                </TabContent>
            </PreviewCard>
        </div >
    )
}

export default Portfoliosilo
