import React, { useEffect, useState } from 'react';
import { Fancybox } from "@fancyapps/ui";
import { Col, Row, Button } from 'reactstrap';

const tagsData = ['All', 'Banners', 'InfoGraphics', 'Logos', 'others'];

const Gallery = ({ images }) => {
    const [selectedTag, setSelectedTag] = useState('All');

    useEffect(() => {
        Fancybox.bind('[data-fancybox]', {});
        return () => {
            Fancybox.destroy();
        };
    }, []);

    const filteredImages = selectedTag === 'All' ? images : images.filter(image => image.tags.includes(selectedTag));

    return (
        <div className="container">
            <div className="tag-container mb-3">
                {tagsData.map((tag, index) => (
                    <Button
                        key={index}
                        size='sm'
                        outline
                        color={selectedTag === tag ? 'danger' : 'light'}
                        onClick={() => setSelectedTag(tag)}
                        className="mx-1 btn-round btn-dim ff-alt"
                    >
                        {tag}
                    </Button>
                ))}
            </div>

            {/* Gallery */}
            <div className="gallery">
                <Row className="g-3">
                    {filteredImages.map((image, index) => (
                        <Col md="3" key={index}>
                            <a href={image.original} data-fancybox="gallery">
                                <img src={image.thumbnail} alt={`Thumbnail ${index + 1}`} />
                            </a>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default Gallery;
