import React, { useState, useCallback, useMemo, useRef } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import DatePicker from "react-datepicker";
import Required from '../Required';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

const initialData = {
    date: null,
    time: '',
    full_name: '',
    email: '',
    phone: '',
    message: '',
};

const availableTimes = [
    { label: '08:00 AM', value: '08:00' },
    { label: '10:00 AM', value: '10:00' },
    { label: '12:00 PM', value: '12:00' },
    { label: '02:00 PM', value: '14:00' },
    { label: '04:00 PM', value: '16:00' },
    { label: '06:00 PM', value: '18:00' },
];

const ConsultationForm = ({
    apiUrl,
    backgroundImage,
    title,
    onSuccess,
    onError,
    maxDays = 30,
}) => {
    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState({ type: '', text: '' });
    const [captchaValue, setCaptchaValue] = useState(null);
    const recaptchaRef = useRef(null);
    const handleCaptcha = useCallback((value) => {
        setCaptchaValue(value);
    }, []);

    const today = useMemo(() => new Date(), []);
    const maxDate = useMemo(() => {
        const date = new Date();
        date.setDate(today.getDate() + maxDays);
        return date;
    }, [today, maxDays]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));

        if (value.trim()) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    }, []);

    const normalizeDate = date => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const handleDateChange = useCallback((date) => {
        setFormData(prev => ({ ...prev, date }));

        const todayNormalized = normalizeDate(new Date());
        const maxDateNormalized = normalizeDate(new Date(todayNormalized.getTime() + maxDays * 24 * 60 * 60 * 1000));
        const selectedDateNormalized = normalizeDate(date);

        if (selectedDateNormalized >= todayNormalized && selectedDateNormalized <= maxDateNormalized) {
            setErrors(prev => ({ ...prev, date: '' }));
        } else {
            setErrors(prev => ({ ...prev, date: 'Please select a date within the next 30 days' }));
        }
    }, [maxDays]);

    const handleTimeChange = useCallback((e) => {
        const { value } = e.target;
        setFormData(prev => ({ ...prev, time: value }));

        if (value) {
            setErrors(prev => ({ ...prev, time: '' }));
        }
    }, []);

    const handlePhoneChange = useCallback((value) => {
        setFormData(prev => ({ ...prev, phone: value }));

        if (value) {
            setErrors(prev => ({ ...prev, phone: '' }));
        }
    }, []);

    const validateForm = useCallback(() => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const todayNormalized = normalizeDate(new Date());

        if (!formData.full_name.trim()) newErrors.full_name = 'Full Name is required';
        if (!formData.email.trim() || !emailRegex.test(formData.email)) newErrors.email = 'Valid email is required';
        if (!formData.phone || !isValidPhoneNumber(formData.phone)) newErrors.phone = 'Valid phone number is required';
        if (!formData.message.trim()) newErrors.message = 'Message is required';
        else if (formData.message.trim().length > 500) newErrors.message = 'Message must be 500 characters or less';
        if (!formData.date) newErrors.date = 'Date is required';
        else {
            const selectedDateNormalized = normalizeDate(formData.date);
            if (selectedDateNormalized < todayNormalized || selectedDateNormalized > new Date(todayNormalized.getTime() + maxDays * 24 * 60 * 60 * 1000)) {
                newErrors.date = 'Please select a date within the next 30 days';
            }
        }
        if (!formData.time) newErrors.time = 'Time is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData, maxDays]);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setSubmitMessage({ type: '', text: '' });

        if (validateForm()) {
            setIsSubmitting(true);
            try {
                const formattedDate = formData.date ? formData.date.toISOString().split('T')[0] : null;

                const dataToSend = {
                    ...formData,
                    date: formattedDate,
                };
                if (!captchaValue) {
                    toast.error("Please complete the reCAPTCHA");
                    return;
                }
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataToSend),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Server responded with an error');
                }

                const result = await response.json();
                setSubmitMessage({ type: 'success', text: 'Consultation scheduled successfully!' });
                setFormData(initialData);
                setCaptchaValue(null);
                if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                }
                onSuccess && onSuccess(result);
                toast.success("Thank you for contacting us, We will reach back to you very soon!", {
                    autoClose: 3000,
                });
            } catch (error) {
                toast.error("Error submitting form:", error);
                setSubmitMessage({ type: 'error', text: error.message || 'An error occurred. Please try again.' });
                onError && onError(error);
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setSubmitMessage({ type: 'error', text: 'Please correct the errors in the form.' });
        }
    }, [captchaValue, formData, validateForm, apiUrl, onSuccess, onError]);

    const renderFormField = useCallback(({ label, placeholder, name, type, value, onChange, error }) => (
        <div className="form-group">
            <label className="form-label" htmlFor={name}>{label}:<Required /></label>
            {type === 'textarea' ? (
                <textarea
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                />
            ) : (
                <input
                    type={type}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                />
            )}
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    ), []);

    return (
        <div className="container-fluid p-3 m-auto" id='contactus' style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            <div className="container py-md-4 py-2">
                <div className='pt-3'>
                    <h4 className='ff text-primary'>{title}</h4>
                </div>
                <Card className='bg-transparent pt-3'>
                    {submitMessage.text && (
                        <div className={`alert alert-${submitMessage.type}`}>
                            {submitMessage.text}
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="gy-3">
                        <Row className='gy-3'>
                            <Col lg="6">
                                <div className="form-group">
                                    <label className="form-label">Select Date:<Required /></label>
                                    <DatePicker
                                        selected={formData.date}
                                        onChange={handleDateChange}
                                        className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                        placeholderText="Select a date YYYY/MM/DD"
                                        dateFormat="yyyy/MM/dd"
                                        minDate={today}
                                        maxDate={maxDate}
                                        filterDate={date => date.getDay() !== 0 && date.getDay() !== 6}
                                    />
                                    {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="form-group">
                                    <label className="form-label">
                                        Select Time (Time Zone: UTC):<Required />
                                    </label>
                                    <select
                                        value={formData.time}
                                        onChange={handleTimeChange}
                                        className={`form-control ${errors.time ? 'is-invalid' : ''}`}
                                    >
                                        <option value="" disabled hidden className='fw-lighter'>
                                            Select a Suitable Time
                                        </option>
                                        {availableTimes.map((time) => (
                                            <option key={time.value} value={time.value}>
                                                {time.label}
                                            </option>
                                        ))}
                                    </select>

                                    {errors.time && <div className="invalid-feedback">{errors.time}</div>}
                                </div>
                            </Col>
                            <Col lg="6">
                                {renderFormField({
                                    label: 'Full Name',
                                    placeholder: 'Enter full name',
                                    name: 'full_name',
                                    type: 'text',
                                    value: formData.full_name,
                                    onChange: handleInputChange,
                                    error: errors.full_name,
                                })}
                            </Col>
                            <Col lg="6">
                                {renderFormField({
                                    label: 'Email Address',
                                    placeholder: 'Enter email address',
                                    name: 'email',
                                    type: 'email',
                                    value: formData.email,
                                    onChange: handleInputChange,
                                    error: errors.email,
                                })}
                            </Col>
                            <Col lg="6">
                                <div className="form-group">
                                    <label className="form-label">Phone Number:<Required /></label>
                                    <div className={`phone-input-container ${errors.phone ? 'is-invalid' : ''}`}>
                                        <PhoneInput
                                            value={formData.phone}
                                            onChange={handlePhoneChange}
                                            international
                                            defaultCountry="US"
                                            className="form-control border-0 p-0 bg-transparent "
                                        />
                                    </div>
                                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                </div>
                            </Col>
                            <Col lg="6">
                                {renderFormField({
                                    label: 'Message',
                                    placeholder: 'Enter your message',
                                    name: 'message',
                                    type: 'textarea',
                                    value: formData.message,
                                    onChange: handleInputChange,
                                    error: errors.message,
                                })}
                            </Col>
                        </Row>
                        <div className="form-row d-md-flex pb-2 justify-content-center align-items-center">
                            <div className="form-group col-md-12 px-2 justify-content-center">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                                    onChange={handleCaptcha}
                                    ref={recaptchaRef}
                                    className='d-flex justify-content-center'
                                />
                            </div>
                        </div>
                        <div className='text-center'>
                            <Button
                                color="primary"
                                size="lg"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit Request'}
                            </Button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );
};

export default ConsultationForm;