import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppWrap from "./global/AppWrap";
import FooterNew from "../pages/axiomlayout/pages/components/FooterNew";
import Cookies from 'js-cookie';

const Layout = ({ title, ...props }) => {
  // useEffect(() => {
  //   const now = new Date();
  //   const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  //   Cookies.set("currentURL", window.location.href, {
  //     domain: process.env.REACT_APP_FIRST_DOMAIN,
  //     expires: expires,
  //   });
  // }, [])
  return (
    <React.Fragment>
      <Head title={process.env.REACT_APP_HEAD_TITLE} />
      <AppRoot className={'overflow-x-hidden'}>
        <AppWrap className={'bg-white'}>
          <Header fixed />
          <Outlet />
          <FooterNew />
          <Footer />
        </AppWrap>
      </AppRoot>
    </React.Fragment>
  );
};
export default Layout;
